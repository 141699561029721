@import url(https://fonts.googleapis.com/css?family=Montserrat|Roboto:400,700&display=swap);
html, body {
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  text-align: left;
  height: 100vh; }

h2, h3, h4, h5 {
  font-family: 'Roboto Slab' , sans-serif; }

h3 {
  font-weight: 700; }

ul {
  padding: 0; }

button:focus {
  outline: 0; }

a, a:visited {
  text-decoration: none;
  color: black; }

.container {
  position: relative;
  padding-bottom: 4em;
  -webkit-transition: background 1s;
  transition: background 1s;
  margin: 0;
  padding: 0; }

@media (min-width: 1281px) {
  .inner-container {
    width: 100%;
    margin: 0 auto; } }

hr.navbar-line {
  background: -webkit-gradient(linear, left top, right top, from(#56ccf2), to(#629EF2));
  background: -webkit-linear-gradient(left, #56ccf2, #629EF2);
  background: linear-gradient(to right, #56ccf2, #629EF2);
  margin: 0;
  height: 5px;
  border: none; }

.profile, .projects, .skills, .work-experience, .education {
  padding: 5%; }
  @media (min-width: 768px) {
    .profile, .projects, .skills, .work-experience, .education {
      padding: 2em 10%; } }
  @media (min-width: 1281px) {
    .profile, .projects, .skills, .work-experience, .education {
      padding: 2em 15%; } }

.skills, .education {
  background: RGBA(98, 158, 242, 0.1); }

.btn {
  border: 1px solid #555555;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0.4em 0.8em;
  font-size: 1em; }

h3.title {
  margin-top: 0;
  text-transform: uppercase;
  text-align: center; }

nav {
  width: 100%;
  height: 3em;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  -webkit-transition: top 0.4s;
  transition: top 0.4s;
  z-index: 2;
  background: #fff;
  border-bottom: 1px solid #eee; }
  nav .dark-theme-btn {
    margin-left: 1em;
    cursor: pointer; }
  nav .dark-theme-btn:hover {
    background-color: #2c2c2c;
    color: #e4e4e4; }
  nav .name {
    font-size: 20px;
    text-align: left; }
  nav .menu {
    padding: 0 1em;
    position: relative;
    position: absolute;
    right: 0;
    margin: 0;
    background: #fff;
    border: 1px solid;
    padding: 0;
    box-sizing: border-box; }
    @media (min-width: 1281px) {
      nav .menu {
        display: flex;
        padding: 0 1em;
        border: 0;
        line-height: 0; } }
    nav .menu li {
      text-decoration: none;
      list-style-type: none;
      border-bottom: 1px solid #ddd;
      padding: 0.5em;
      text-align: left;
      cursor: pointer; }
      @media (min-width: 1281px) {
        nav .menu li {
          padding: 0 1em;
          border: 0; } }
    nav .menu li:last-child {
      border-bottom: 0; }
    nav .menu li a:hover {
      border-bottom: 2px solid #629EF2; }
  nav .navbar-icon {
    position: relative;
    width: 2.5em;
    background: none;
    margin-right: 1em;
    display: block; }
    @media (min-width: 1281px) {
      nav .navbar-icon {
        display: none; } }
    nav .navbar-icon hr.line {
      border: 1px solid black;
      width: 100%;
      padding: 2px;
      background: black;
      border-radius: 5px; }

.nav-hidden {
  top: -50px; }

.resume {
  align-self: center;
  width: 6em;
  border: 0; }

.resume:hover {
  background: -webkit-gradient(linear, left top, right top, from(#56ccf2), to(#629EF2));
  background: -webkit-linear-gradient(left, #56ccf2, #629EF2);
  background: linear-gradient(to right, #56ccf2, #629EF2);
  color: #fff; }

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: -webkit-gradient(linear, left top, right top, from(#56ccf2), to(#629EF2));
  background: -webkit-linear-gradient(left, #56ccf2, #629EF2);
  background: linear-gradient(to right, #56ccf2, #629EF2);
  color: #fff;
  -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 95%);
          clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 95%); }
  @media (min-width: 1281px) {
    .profile {
      -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%);
              clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%); } }
  .profile h2 {
    margin: 0;
    font-size: 2em; }
  .profile h4 {
    font-size: 1.5em; }
  .profile h2, .profile h4 {
    text-transform: uppercase;
    align-self: center;
    margin: 0.5em 0; }
  .profile header {
    display: flex;
    align-items: center;
    flex-direction: column; }

.profile-picture {
  max-width: 100%;
  height: auto;
  align-self: center;
  border-radius: 10px; }

.contacts ul li {
  padding: 0.3em 0; }

.social {
  display: flex; }
  .social li {
    list-style-type: none; }

.social-btn {
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  margin: 0 0.5em;
  padding: 0.5em;
  color: #fff;
  background-color: #e8e8e8; }

.social-btn:hover {
  background: -webkit-gradient(linear, left top, right top, from(#56ccf2), to(#629EF2));
  background: -webkit-linear-gradient(left, #56ccf2, #629EF2);
  background: linear-gradient(to right, #56ccf2, #629EF2); }

.skills h3.title {
  margin-top: 0; }

.skills-container {
  display: flex;
  flex-direction: column; }
  @media (min-width: 1281px) {
    .skills-container {
      flex-direction: row; } }

.lang {
  border-radius: 5px;
  margin: 1em 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5em 1em 0 #373737;
  background: #fff; }
  @media (min-width: 1281px) {
    .lang {
      width: 33%; } }
  .lang h3 {
    margin-bottom: 0; }

@media (min-width: 1281px) {
  .back-end {
    margin: 1em 2.5em; } }

.skills-list {
  text-align: center; }
  .skills-list li {
    border: 1px solid #629EF2;
    border-radius: 5px;
    padding: 6px 8px;
    margin: 0.5em;
    font-size: 0.8em;
    box-sizing: border-box;
    display: inline-block; }

.progress {
  height: 10px;
  position: relative;
  background: #ccc;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 1.5em; }
  .progress .percentage {
    float: right;
    background: inherit;
    line-height: 0.9;
    font-size: 0.8em; }

.progress > span {
  display: block;
  height: 100%;
  border-radius: 25px;
  background: -webkit-gradient(linear, left top, right top, from(#56ccf2), to(#629EF2));
  background: -webkit-linear-gradient(left, #56ccf2, #629EF2);
  background: linear-gradient(to right, #56ccf2, #629EF2); }

.project-container {
  box-sizing: border-box;
  border: 1px solid #222;
  border-radius: 5px;
  margin: 1em 0;
  position: relative;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 90% 1 auto; }
  @media (min-width: 768px) {
    .project-container {
      flex-direction: row;
      flex: 90% 1 auto; } }
  @media (min-width: 1281px) {
    .project-container {
      display: grid;
      grid-template-columns: 50% 50%; } }

.project-image {
  flex-basis: 90%;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  background-color: white;
  z-index: 1;
  align-self: center; }
  .project-image img {
    position: relative;
    float: left;
    max-width: 100%;
    min-height: 200px;
    height: auto;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -webkit-transition: 0.8s;
    transition: 0.8s;
    align-self: center; }
    @media (min-width: 1281px) {
      .project-image img {
        max-width: 100%; } }

.image-hide {
  margin-left: -90%;
  -webkit-transition: 0.8s;
  transition: 0.8s; }

.info {
  width: 10%;
  display: none;
  flex-direction: column;
  padding: 1em 0;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 1;
  border-right: 1px soild #ccc;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }
  @media (min-width: 768px) {
    .info {
      display: flex; } }
  @media (min-width: 1281px) {
    .info {
      display: none; } }
  .info p {
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    -webkit-text-orientation: upright;
            text-orientation: upright;
    margin: 0;
    line-height: 40px; }
  .info a {
    margin: 0.5em; }
  .info button {
    padding: 0;
    padding-top: 0.5em;
    border: 0;
    background: white;
    cursor: pointer; }
  .info button:focus {
    border: 0;
    outline: 0; }

.project-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .project-details {
      width: 90%;
      margin-left: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 1281px) {
    .project-details {
      width: 100%;
      margin-left: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .project-details a {
    margin-bottom: 0.5em; }
    .project-details a svg {
      margin-bottom: 0.5em; }
  .project-details p {
    padding: 0 0.5em;
    min-width: 15em;
    max-width: 20em;
    margin: 0; }
    @media (min-width: 768px) {
      .project-details p {
        max-width: 90%;
        min-width: 80%; } }
    @media (min-width: 1281px) {
      .project-details p {
        max-width: 90%;
        min-width: 80%; } }

@media (min-width: 768px) {
  .project-details.open {
    margin-left: 0;
    -webkit-transition: 0.8s;
    transition: 0.8s; } }

@media (min-width: 768px) {
  .project-details.closed {
    margin-left: -100%;
    -webkit-transition: 0.8s;
    transition: 0.8s; } }

.project-skills, .project-works {
  padding: 0 0.5em;
  min-width: 15em;
  max-width: 20em; }
  @media (min-width: 768px) {
    .project-skills, .project-works {
      max-width: 80%;
      min-width: 75%; } }
  @media (min-width: 1281px) {
    .project-skills, .project-works {
      max-width: 80%;
      min-width: 75%; } }

.project-works {
  padding-left: 3em; }

.project-skills {
  list-style-type: none;
  margin-top: 0;
  text-align: center; }
  .project-skills li {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 3px;
    margin: 3px;
    border: 1px solid #ddd;
    border-radius: 3px; }
  .project-skills .project-skills-title {
    max-width: 0;
    min-width: 0;
    padding: 0;
    display: inline;
    font-weight: 700;
    text-transform: capitalize; }
  @media (min-width: 1281px) {
    .project-skills {
      display: inline; } }

.work-experience h3.title {
  margin-top: 0; }

.record h3 {
  margin-bottom: 0; }

.record p {
  margin: 0; }

.record .details {
  color: #b3b3b3; }
  @media (min-width: 1281px) {
    .record .details {
      display: inline; } }

.record .date {
  color: #b3b3b3; }
  @media (min-width: 1281px) {
    .record .date {
      margin-top: 0;
      float: right; } }

.record ul {
  margin-left: 1em; }
  .record ul li {
    list-style-type: disc; }

.darkTheme {
  background: #121212;
  color: #e4e4e4;
  -webkit-transition: background 1s;
  transition: background 1s; }
  .darkTheme a, .darkTheme a:visited {
    color: #e4e4e4; }
  .darkTheme .profile, .darkTheme .skills, .darkTheme .projects, .darkTheme .work-experience, .darkTheme .education {
    -webkit-transition: background 1s;
    transition: background 1s;
    background: #222222; }
  .darkTheme .profile {
    -webkit-clip-path: none;
            clip-path: none; }
  .darkTheme .skills, .darkTheme .projects, .darkTheme .work-experience, .darkTheme .education {
    margin-top: 1.5em; }
  .darkTheme .skills, .darkTheme .work-experience {
    border-radius: 0; }
  .darkTheme .btn {
    background-color: #2c2c2c;
    color: #e4e4e4; }
  .darkTheme .social-btn {
    background-color: #2c2c2c; }
  .darkTheme .project-details .social-btn {
    background-color: #373737; }
  .darkTheme .project-container {
    background-color: #2c2c2c; }
  .darkTheme nav {
    border-bottom: none;
    background: #333333; }
    .darkTheme nav .menu {
      background: #333333; }
      .darkTheme nav .menu li {
        color: #e4e4e4; }
    .darkTheme nav .navbar-icon hr.line {
      border: 1px solid #e4e4e4;
      background: #e4e4e4; }
    .darkTheme nav .dark-theme-btn:hover {
      background-color: #fff;
      color: black; }
  .darkTheme .info, .darkTheme .info button {
    color: #e4e4e4;
    background: #373737; }
  .darkTheme .lang {
    background: #2c2c2c;
    box-shadow: none; }

