@import url('https://fonts.googleapis.com/css?family=Montserrat|Roboto:400,700&display=swap');


$dp00: #121212;
$dp01: #1d1d1d;
$dp02: #222222;
$dp03: #242424;
$dp04: #272727;
$dp06: #2c2c2c;
$dp08: #2d2d2d;
$dp12: #333333;
$dp16: #353535;
$dp24: #373737;
$color: #e4e4e4;

$neon-blue: #56ccf2;
$blue: #629EF2;
$light-blue: RGBA(98,158,242,0.1);
$tablet: 768px;
$desktop: 1281px;

@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;    
  text-align: left; 
  height: 100vh;
}

h2, h3, h4, h5 {
  font-family: 'Roboto Slab' , sans-serif;
}

h3 {
  font-weight: 700;
}

ul {
  padding: 0
}

button:focus {
  outline: 0;
}

a, a:visited {
  text-decoration: none;
  color: black;
} 

.container {
  position: relative;  
  padding-bottom: 4em;
  transition: background 1s;
  margin: 0;
  padding: 0;
}

.inner-container {
  @include desktop() {
    width: 100%;
    margin: 0 auto;
  }
}

hr.navbar-line {
  background: linear-gradient(to right, #56ccf2, $blue);
  margin: 0;
  height: 5px;
  border: none;
}

.profile, .projects, .skills, .work-experience, .education {

  padding: 5%;

  @include tablet() {
    padding: 2em 10%;
  }

  @include desktop() {
    padding: 2em 15%;
  }
}

.skills, .education {
  // clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 90%);
  background: $light-blue;
}

.btn {
  border: 1px solid #555555;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0.4em 0.8em;
  font-size: 1em;
}

h3.title {
  margin-top: 0;
  text-transform: uppercase;
  text-align: center;
}

//FIXIT: =========== Navigation Bar ================= //

nav {
  width: 100%;
  height: 3em;
  top:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  transition: top 0.4s;
  z-index: 2;
  background: #fff;
  border-bottom: 1px solid #eee;
  

    .dark-theme-btn {
      margin-left: 1em;
      cursor: pointer;
    }

    .dark-theme-btn:hover {
      background-color: $dp06;
      color: $color
    }

    .name {
      font-size: 20px;
      text-align: left;
      
    }

    .menu {
      padding: 0 1em;
      position: relative;
      position: absolute;
      right: 0;
      margin: 0;
      background: #fff;
      border: 1px solid;
      padding: 0;
      box-sizing: border-box;

      @include desktop() {
        display: flex;
        padding: 0 1em;
        border:0 ;
        line-height: 0;
        
      }  

      li {
        text-decoration: none;
        list-style-type: none;
        border-bottom: 1px solid #ddd;
        padding: 0.5em;
        text-align: left;
        cursor: pointer;

        @include desktop() {
          padding: 0 1em;
          border:0;
        }  

      }

      li:last-child {
        border-bottom: 0;
      }

      li a:hover {
        border-bottom: 2px solid $blue;
      }
    }
    .navbar-icon {
      position: relative;
      width: 2.5em;
      background:none;
      margin-right: 1em;
      display:block;

      @include desktop() {
        display: none;
      }

        hr.line {
          border: 1px solid black;
          width: 100%;
          padding: 2px;
          background: black;
          border-radius: 5px;
        }
    }

}

.nav-hidden {
  top: -50px;
}

.resume {
  align-self: center;
  width: 6em;
  border: 0;
}

.resume:hover {
  background: linear-gradient(to right,#56ccf2, $blue);
  color: #fff;
} 


//FIXME: =========== Profile ================= //


.profile {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to right,#56ccf2, $blue);
  color: #fff;
  clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 95%);

  @include desktop(){
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%);
  }
  h2 {
    margin:0;
    font-size:2em
  }

  h4 {
    font-size: 1.5em;
  }
  
  h2, h4 {
    text-transform: uppercase;
    align-self: center;
    margin: 0.5em 0;
  }

  header {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.profile-picture {
  max-width: 100%;
  height: auto;
  align-self: center;
  border-radius: 10px;

} 

.contacts {
  ul {

    li {
      padding: 0.3em 0;
    }
  }
}

.social {
  display: flex;

  li {
    list-style-type: none;
  }
}

.social-btn {
  height: 1.2em;
  width: 1.2em;
  border-radius: 5px;
  margin: 0 0.5em;
  padding: 0.5em;
  color: #fff;
  background-color: #e8e8e8;
  
}

.social-btn:hover {
  background: linear-gradient(to right,#56ccf2, $blue);
}

//FIXME: =========== Skills ================= //

.skills h3.title {
  margin-top: 0;
}

.skills-container {
  display: flex;
  flex-direction: column;
  

  @include desktop(){
    flex-direction: row;
  }
}

.lang {
  border-radius: 5px;
  margin: 1em 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.5em 1em 0 $dp24;
  background: #fff;

  @include desktop() {
    width: 33%;
  }

  h3 {
    margin-bottom: 0;
  }
}

.back-end {
  
  @include desktop() {
    margin: 1em 2.5em;
  }
}


.skills-list {
  text-align: center;

  li {
    border: 1px solid $blue;
    border-radius: 5px;
    padding: 6px 8px;
    margin: 0.5em;
    font-size: 0.8em;
    box-sizing: border-box;
    display: inline-block;
  }
}
  
.progress {
  height: 10px;
  position: relative;
  background: #ccc;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 1.5em;

  .percentage {
    float: right;
    background: inherit;
    line-height: 0.9;
    font-size: 0.8em;
  }
}

.progress > span {
  display: block;
  height: 100%;
  border-radius: 25px;
  background: linear-gradient(to right,#56ccf2, $blue);
}


//FIXME: =========== Project ================= //

.project-container {
  box-sizing: border-box;
  border: 1px solid #222;
  border-radius: 5px;
  margin: 1em 0;
  position: relative;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 90% auto;
  
  @include tablet() {
    flex-direction: row;
    flex: 90% 10% auto;
  }

  @include desktop() {
    display: grid;
    grid-template-columns: 50% 50%; 
  }

}

.project-image {
  flex-basis: 90%;
  transition: 0.8s;
  background-color: white;
  z-index: 1;
  align-self: center;

  img {
    position: relative;
    float: left;
    max-width: 100%;
    min-height: 200px;
    height: auto;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: 0.8s;
    align-self: center;
  
    @include desktop(){
      max-width: 100%;
    }
  }

}

.image-hide {
  margin-left : -90%;
  transition: 0.8s;
}

.info {
  width: 10%;
  display: none;
  flex-direction: column;
  padding: 1em 0;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 1;
  border-right: 1px soild #ccc;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  @include tablet(){
    display: flex;
  }

  @include desktop(){
    display:none;
  }

   p {
     writing-mode: vertical-rl;
     text-orientation: upright;
     margin:0;
     line-height: 40px;
   }

   a {

     margin: 0.5em;
   }

   button {
    padding: 0;
    padding-top: 0.5em;
    border: 0;
    background: white;
    cursor: pointer;
   }

   button:focus {
    border: 0;
    outline: 0;
   }
}

.project-details {

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include tablet() {
    width: 90%;
    margin-left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include desktop() {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  a {
    margin-bottom: 0.5em;

    svg {
      margin-bottom: 0.5em;
    }
  }
  
  p {
    padding: 0 0.5em;
    min-width: 15em;
    max-width: 20em;
    margin: 0;

    @include tablet() {
      max-width: 90%;
      min-width: 80%;
    }

    @include desktop(){
      max-width: 90%;
      min-width: 80%;
    }
  }


}

.project-details.open {

  @include tablet(){
    margin-left: 0;
    transition: 0.8s;
  }

}

.project-details.closed {
  @include tablet() {
    margin-left: -100%;
    transition: 0.8s;
  }

}


.project-skills, .project-works {
  padding: 0 0.5em;
  min-width: 15em;
  max-width: 20em;

  @include tablet(){
    max-width: 80%;
    min-width: 75%;
  }

  @include desktop(){
    max-width: 80%;
    min-width: 75%;
  }

}

.project-works {
  padding-left: 3em;
}

.project-skills {
  list-style-type: none;
  margin-top: 0;
  text-align: center;

  li {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 3px;
    margin: 3px;
    border: 1px solid #ddd;
    border-radius: 3px;
  }

  .project-skills-title {
    max-width: 0;
    min-width: 0;
    padding: 0;
    display:inline;
    font-weight: 700;
    text-transform: capitalize;
    
  }

  @include desktop(){
    display: inline;
  }
}


//FIXME: =========== Work Experience ================= //

.work-experience h3.title {
  margin-top: 0;
}
.record {

  h3 {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }

  .details {
    color: #b3b3b3;
    @include desktop(){

      display: inline;
    }
  }

  .date {

    color: #b3b3b3;

    @include desktop(){
      margin-top: 0;
      float:right;
    }
  }

  ul {
    margin-left: 1em;
    li {
      list-style-type: disc;
    }
  }
}

//FIXME: =========== Education ================= //


//FIXME: DARKTHEME SETTINGS //

.darkTheme {

  background: $dp00;
  color: $color;
  transition: background 1s;

  a, a:visited {
    color: $color;
  }

  .profile, .skills, .projects, .work-experience, .education {
    transition: background 1s;
    background: $dp02;
  }

  .profile {
    clip-path: none;
  }

  .skills, .projects, .work-experience, .education {
    margin-top: 1.5em;
  }

  .skills, .work-experience {
    border-radius: 0;
  }


  .btn {
    background-color: $dp06;
    color: $color
  }

  .social-btn {
    background-color: $dp06;
  }

  .project-details {
    .social-btn {
      background-color: $dp24;
    }
  }

  .project-container {
    background-color: $dp06;
  }

  nav {

    border-bottom: none;

    background: $dp12;
    .menu{
    background: $dp12;

        li {
          color: $color;
        }
    }

    .navbar-icon {
      
        hr.line {
          border: 1px solid $color;
          background: $color;
        }
    }

    .dark-theme-btn:hover {
      background-color: #fff;
      color: black;
    }
  }

  .info, .info button {
    color: $color;
    background: $dp24
  }

  .lang{
    background: $dp06;
    box-shadow: none;
  }
}